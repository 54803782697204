import axios from "axios";
import { getApiToken } from "states/userProfileState";
import { setToastState } from "states/toastState";
import { setUserProfileState } from "states/userProfileState";
import { KEY_USER_PROFILE } from "constants/constants";

const version = "v1";

const instance = axios.create({
  baseURL: `${process.env[
    `REACT_APP_BASE_API_URL_${process.env.REACT_APP_PROJECT_NAME_ABBV}`
  ]
    }${version}`,
  timeout: 10000
});

instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (getApiToken()) {
    config.headers.Authorization = `Bearer ${getApiToken()}`;
  }
  return config;
}, undefined);

// Add a response interceptor
instance.interceptors.response.use(function (res) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // SESSION TIMEOUT HANDLING
  if (res.status !== 200 || res.data.code !== 200) {
    if (res.data.code === 501) {
      setUserProfileState({});
      localStorage.removeItem(KEY_USER_PROFILE);
    }
    setToastState({ status: "danger", message: res?.data?.message });
    return Promise.reject(res);
  }
  return res;
}, undefined);

const httpRequestGet = (url, config) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, config)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => reject(e));
  });
};

const httpRequestPost = (url, body, config) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, body, config)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => reject(e));
  });
};

const httpRequestPostFormData = (url, body, config) => {
  let formData = new FormData();
  Object.keys(body).forEach(key => {
    if (key === "document" || key === 'photo') {
      let document = Array.from(body[key]);
      if (!document.length) {
        formData.append(key, body[key]);
      } else {
        for (let i = 0; i < document.length; i++) {
          formData.append(key, document[i]);
        }
      }
    } else {
      Array.isArray(body[key])
        ? body[key].forEach((value, index) =>
          Object.entries(value).map(([k, v]) =>
            typeof v === "object"
              ? Object.entries(v).map(([m, n]) =>
                formData.append(`${key}[${index}][${k}][${m}]`, n)
              )
              : formData.append(`${key}[${index}][${k}]`, v)
          )
        )
        : formData.append(key, body[key]);
    }
  });
  return new Promise((resolve, reject) => {
    instance
      .post(url, formData, config)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => reject(e));
  });
};

const httpRequestPut = (url, body, config) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, body, config)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => reject(e));
  });
};

const httpRequestDelete = (url, config) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, config)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => reject(e));
  });
};

export {
  httpRequestGet,
  httpRequestPost,
  httpRequestPostFormData,
  httpRequestPut,
  httpRequestDelete
};
