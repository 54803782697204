import React, { useState, useEffect } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CInput,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useModalStore } from "states/modalState";

export const Modal = () => {
  const [modalStore] = useModalStore();
  const [showModal, setShowModal] = useState(false);
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if (modalStore.title) {
      setShowModal(true);
    }
  }, [modalStore]);

  const handleDoAction = () => {
    modalStore.action(inputText || "");
    clearInputAndCloseModal();
  };

  const clearInputAndCloseModal = () => {
    setInputText("");
    setShowModal(false);
  };

  const handleFocus = () => {
    if (document.getElementById("searchInput")) {
      document.getElementById("searchInput").focus();
    }
  };

  const handleDownload = (doc) => {
    fetch(`${process.env[
      `REACT_APP_BASE_STATIC_URL_${process.env.REACT_APP_PROJECT_NAME_ABBV}`
    ]}${doc}`).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = doc;
        alink.click();
      })
    })
  };

  return (
    <>
      <CModal
        show={showModal}
        id="myModal"
        className={modalStore.children ? "full-screen" : ""}
        onOpened={handleFocus}
        onClose={clearInputAndCloseModal}
      >
        <CModalHeader closeButton>
          <b>{modalStore.title}</b>
        </CModalHeader>
        <CModalBody>
          {modalStore.document?.length ? (
            <CTabs activeTab="0">
              <CNav variant="tabs">
                {modalStore.document.map((doc, i) => (
                  <CNavItem key={doc}>
                    <CNavLink data-tab={i}>Document {i + 1}</CNavLink>
                  </CNavItem>
                ))}
              </CNav>
              <CTabContent>
                {modalStore.document?.map((doc, i) => (
                  <CTabPane key={doc} data-tab={i}>
                    <CButton
                      color="primary"
                      className="my-2 float-right"
                      onClick={() => handleDownload(doc)}
                    >
                      <CIcon name="cil-cloud-download" />
                    </CButton>
                    {doc.substring(doc.length - 4) ===
                      ".pdf" ? (
                      <iframe
                        title="pdf"
                        className="full-width"
                        src={`${process.env[
                          `REACT_APP_BASE_STATIC_URL_${process.env.REACT_APP_PROJECT_NAME_ABBV}`
                        ]}${doc}`}
                        style={{ height: "70vh" }}
                      ></iframe>
                    ) : (
                      <img
                        style={{ width: "100%" }}
                        src={`${process.env[
                          `REACT_APP_BASE_STATIC_URL_${process.env.REACT_APP_PROJECT_NAME_ABBV}`
                        ]}${doc}`}
                        alt="documents"
                      />
                    )}
                  </CTabPane>
                ))}
              </CTabContent>
            </CTabs>
          ) : <></>}
          {modalStore.message}
          {modalStore.children}

          {modalStore.withInput && (
            <CInput
              className="mt-3"
              id="searchInput"
              value={inputText}
              placeholder={modalStore.inputPlaceholder}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  handleDoAction();
                }
              }}
              onChange={e => setInputText(e.target.value)}
            />
          )}
        </CModalBody>
        {modalStore.btnLabel && (
          <CModalFooter>
            <CButton color="secondary" onClick={clearInputAndCloseModal}>
              Cancel
            </CButton>
            <CButton onClick={handleDoAction} color="primary">
              {modalStore.btnLabel}
            </CButton>
          </CModalFooter>
        )}
      </CModal>
    </>
  );
};
